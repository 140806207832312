import React from "react";
import { Link } from "gatsby";

import Barre from "../components/barre";
import Footer from "../components/footer";
import { GatsbySeo } from "gatsby-plugin-next-seo"

import "../css/style.css";
import "../css/home.css";
import "../css/realisation.css";

import 'aos/dist/aos.css';
import AOS from 'aos/dist/aos.js';

class Realisation extends React.Component {
  componentDidMount() {
    AOS.init();
  }
  render() {
    return (
      <div>
        <GatsbySeo
      language='fr'
      title='Mariée Création Passion / Créations sur Mesure'
      description='Mariée Création Passion situé sur la Côte d Azur près de Cannes, crée et réalise vos modèles exclusifs sur mesures'
    />
        <Barre />
        <div class="container Titre">
          <div class="row">
            <h2  id="TitreRealisation">Créations sur Mesure</h2>
            <div class="Mozaic col-12 col-lg-5">
              <div data-aos="zoom-in" data-aos-duration="500" id="Moz4"></div>
            </div>
            <div class="Mozaic2 col-12 col-lg-7 row">
              <div class="col-12 col-lg-6">
                <div data-aos="zoom-in" data-aos-delay="50" data-aos-duration="500" id="Moz5"></div>
              </div>
              <div class="col-12 col-lg-6">
                <div data-aos="zoom-in" data-aos-delay="100" data-aos-duration="500" id="Moz6"></div>
              </div>
            </div>

            

            <div class="col-12 col-lg-12 TextMoz text-center text-xl-start">
              <p data-aos="fade-top" data-aos-duration="500">
                Robe de mariée raffinée en soie ivoire, dentelle précieuse et
                perles...
                <br />
                <br />
                <b>Créations uniques sur mesure</b> pour cette <b>étole</b> et ce <b>voile</b>.
                <br />
                <b>Étole</b> très fluide confectionnée dans la même soie que la robe.
                <br />
                Ses extrémités sont agrémentées d’applications de motifs floraux
                découpés dans la même dentelle que les manches, <mark>« cousues
                main »</mark> puis perlées, rappelant ainsi l’incrustation perlée du
                buste.
                <br />
                <br />
                Même travail délicat d’applications des mêmes motifs, parsemées <mark>« ça et là »</mark> sur ce <b>long voile</b> ivoire,
                afin de créer encore une unité avec la robe et son étole.
              </p>
            </div>
          </div>
        </div>
        <div class="space mx-auto"></div>
        <div class="container pt-3 pb-3">
          <div class="row">
          <div class="Mozaic col-12 col-lg-5">
              <div  data-aos="zoom-in" data-aos-duration="500" id="Moz1"></div>
            </div>
            <div class="Mozaic2 col-12 col-lg-7 row">
              <div class="col-12 col-lg-6">
                <div data-aos="zoom-in" data-aos-delay="50"  id="Moz2"></div>
              </div>
              <div class="col-12 col-lg-6">
                <div data-aos="zoom-in" data-aos-delay="100" id="Moz3"></div>
              </div>
            </div>

            <div class="col-12 col-lg-12 TextMoz text-center text-xl-start">
              <p>
                Robe de mariée en satin <mark>« duchesse »</mark> blanc cassé, buste et manches
                en dentelle, l’épaule est soulignée d’une rangée de roses en satin.
                <br />
                <br />
                <b>Création et confection <mark>« sur mesure »</mark></b> de la <b>robe</b> de la
                <b> demoiselle d’honneur</b> et du <b>coussin porte-alliances</b>.
                <br />
                <br />
                <b>Cette robe</b> assortie à celle de la mariée tant au niveau des matières que des détails.
                <br />
                Corsage en dentelle dont la taille est agrémentée de 3 roses comme
                la robe de la mariée et choix d’une jupe en satin doublée
                d’organza pour donner de la légèreté...
                <br />
                <br />
                <b>Le coussin porte-alliances</b> forme <mark>« cœur »</mark> est confectionné avec les 3
                matières des robes: satin duchesse, dentelle et organza drapé en
                rappel de la jupe de la demoiselle d’honneur.
                <br />
                Trois roses en satin également soulignent l’arrondi du cœur en
                rappel à la robe de la mariée.
              </p>
            </div>
            
          </div>
        </div>
        <div class="space mx-auto"></div>
        <div class="container">
          <div class="row LesMozaic">
            <div class="Mozaic col-12 col-lg-5">
              <div data-aos="zoom-in" data-aos-duration="500" id="Moz7"></div>
            </div>
            <div class="Mozaic2 col-12 col-lg-7 row">
              <div class="col-12 col-lg-6">
                <div data-aos="zoom-in" data-aos-delay="50" data-aos-duration="500" id="Moz8"></div>
              </div>
              <div class="col-12 col-lg-6">
                <div data-aos="zoom-in" data-aos-delay="100" data-aos-duration="500" id="Moz9"></div>
              </div>
            </div>

            

            <div class="col-12 col-lg-12 TextMoz text-center text-xl-start" id="DernierTextMoz">
              <p data-aos="fade-top" data-aos-duration="500">
                Ligne sobre pour cette robe de mariée en soie sauvage écrue brodée
                de fleurs.
                <br />
                <br />
                <b>L’étole</b> et le <b>coussin porte-alliances</b> aussi en soie sauvage ont
                été <b>crées et confectionnés <mark>« sur mesure »</mark></b>. La matière noble ainsi
                que ses broderies se suffisent à eux-mêmes d’où le choix d’une
                étole simple et entièrement doublée, pour garder une ligne pure...
                <br />
                <br />
                Le <b>coussin porte-alliances</b> est seulement agrémenté de galons en
                satin fuchsia en rappel des roses du chignon de la mariée et de 2
                <mark>« cœurs »</mark> en dentelle sur lesquels seront fixées par un nœud les
                alliances des futurs époux, pour ne pas surcharger le coussin.
                <br />
                <br />
              </p>
            </div>
          </div>
        </div>
          <Footer />
        </div>
          );
}
}
          export default Realisation